
@import url('https://fonts.googleapis.com/css2?family=Rubik+Microbe&display=swap');
.feature_subtitle{
    color:#FE724C;
}
.feature_title span{
    color:#FE724C;
}
.feature_text{
    color:#777;
}
.feature_item p{
    color:#777;
}
.feature_item:hover img{
    transform:translateX(40px);
}
.food_category{
    background:#FE724C;
    text-align:center;
    margin-top: 30px;
    padding: 20px 0px;
    border-radius: 8px;
}
.food_category button{
    border: none;
    outline: none;
    padding: 7px 20px;
    background: transparent;
    color: #fff;
}
.food_category button img{
    width: 20px;
    height: 20px;
}
.foodBtnActive{
    background: #fff !important;
    border-radius: 5px;
    color: #FE724C !important;

}
.tasty_title{
color:#FE724C;
}
.tasty_desc{
 color:#777;
 line-height: 28px;
}
.choose_title{
  font-weight: 600;
  margin-bottom: 10px;  
}
.choose_title i{
    font-weight: 500 !important;
    color:#FE724C;
}
.choose_desc{
    color:#777;
}
.testimonial_title{
    color:#FE724C;
}
.testimonial_sec span{
    color:#FE724C;
}
.testimonial_desc{
  color:#777; 
  line-height: 30px; 
}
@media only screen and (max-width:768px){

   .hero_content h6{
     font-size:0.9rem ;
   } 
   .hero_content p{
    font-size: .9rem;
   }
   .hero_title{
    font-size:1.7rem;
    line-height: 40px;
   }
   .hero_btn button{
    padding: 5px 15px;
    font-size: 0.7rem;
   }
   .hero_service p{
    padding: 0;
    font-size: 0.6rem;
   }
   .feature_item h5{
    font-size: 0.9rem;

   }
   .feature_item p{
    font-size: 0.8rem;
    margin-bottom: 0;
   }
   .feature_subtitle{
     font-size: 1rem;
   }
   .feature_text{
    font-size: 0.8rem;
    margin-bottom: 0;

   }
   .food_category{
    padding: 15px;
   }
   .food_category button{
     padding: 5px 15px;
     font-size: 0.7rem;

   }
   .tasty_desc{
    font-size: 0.8rem;
    margin-bottom: 0;
   }
   .choose_title{
    font-size: 0.8rem;
   }
   .choose_desc{
    font-size: 0.7rem;
   }
   .testimonial_title{
    font-size: 1rem;
   }
   .testimonial_sec{
    font-size: 1.5rem;
   }
   .testimonial_desc{
    font-size: 0.9rem;
   }

}
@media only screen and (max-width:576px) {
    .hero_content{
        text-align: center;
    }
   .hero_content h6{
     font-size:0.9rem ;
   } 
   .hero_content p{
    font-size: .9rem;
   }
   .hero_title{
    font-size:1.7rem;
    line-height: 40px;
   }
   .hero_btn{
    justify-content: center;
   }
   .hero_btn button{
    padding: 5px 15px;
    font-size: 0.7rem;
   }
   .hero_service{
    justify-content: center;
   }
   .hero_service p{
    padding: 0;
    font-size: 0.6rem;
   }
   .choose_sec{
    padding-top: 0 !important;
 }
    
}
@media only screen and (max-width:375px) {
    .food_category{
        padding: 10px;

    }
    .food_category button{
        padding: 5px 4px;
        font-size: 0.7rem;
      }

}