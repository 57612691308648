.paginationBtns{
   width: 80%;
   height: 40px; 
   margin: auto;
   display: flex;
   align-items: center;
   justify-content: center;
   column-gap:1rem;
   list-style: none;
   margin-top: 30px;
}
.paginationBtns a{
    padding: 7px 13px;
    background: #FE724C;
    color: #fff !important;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.paginationBtns a:hover{
   background: gray; 
}