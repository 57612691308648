.review_test{
    color:#777;
    line-height: 30px;
    margin: 40px 0px;
}


.pic-test{
    width: 50px;
    height: 50px;
    border-radius: 50% !important;
}
@media only screen and (max-width:768px) {
    .review_test{
        font-size: .9rem;
        margin: 18px 0px;
    } 
    .pic-test{
        width: 35px;
        height: 35px;
    }
    .slider_content h6{
       font-size: 0.8rem; 

    }
    
}