.category_item{
    background: transparent;
    border: 2px solid #FE724C;
    padding: 27px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.4s;
    position: relative;
}
/* .category_item:hover{
    transform:translateY(-15px);
} */
.category_item:hover img{
    top: -40%;
    left: 36%;
    position: absolute;
}
@media only screen and (max-width:992px){
    .category_item:hover{
        transform:translateY(-15px);
    }

 .category_item:hover img{
       top: unset;
       left: unset;
       position:unset;
    }
}
@media only screen and (max-width:768px){
    .category_item{
        position:none;
        margin-top: 20px;
    }
    .category_item:hover img{
       top: unset;
       left: unset;
       position:unset;
    }
}
@media only screen and (max-width:576px){
     
    .category_item{
        position:none;
        padding: 18px 10px;
    }
    .category_item:hover img{
       top: unset;
       left: unset;
       position:unset;
    }
}