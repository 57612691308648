/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik+Microbe&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
body{
  background-color: #fcfcfc;
  font-family: 'Poppins', sans-serif;
}
h1,h2,h3,h4,h5,h6{
  font-family: 'Playfair Display', serif !important;
}
a{
  text-decoration: none !important;
  color:unset !important;
}
h1,h2{
  font-size: 2.1rem;
  font-weight: 600;
  color:#272D2F;
}
section{
  padding: 60px 0px;
}

@media only screen (max-width:768px) {
  section {
    padding: 40px 0px;
  }
  h1,h2{
    font-size: 1.5rem;
  }
}