
.search_widget,.sorting_widget select{
    padding: 7px 15px;
    border: 1px solid #FE724C;
    border-radius: 5px;
    cursor: pointer;
}

.search_widget input{
  border:none;
}
.search_widget input:focus {
    outline:none;
}
@media only screen and (max-width:768px) {

  .search_widget{
     width: 100%;
     margin-bottom: 20px;
  }
  .search_widget input{
    width: 100%;
    font-size: 0.8rem;
  }
  .search_widget select
  {
  width: 100% !important;
  font-size: 0.8rem;
  }
}