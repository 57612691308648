.checkout_bill{
    padding: 30px;
    background: #EFBF38;
    border-radius: 4px;
}
.checkout_total{
    border-top: 1px solid #FE724C;
    padding-top: 30px;
}
@media only screen and (max-width: 768px) {
    .checkout_bill h6 {
      font-size: 0.9rem;
    }
    .checkout_bill {
      margin-top: 40px;
    }
  }