.footer{
    padding-top: 50px;
    padding-bottom: 30px;
    background: #EFBF38;
}
.footer_logo p{
    font-size: .9rem;
    color:#272D2F;
    line-height: 28px;
}
.delivery_time-item{
    background:transparent !important;
}
.delivery_time-item span{
  font-weight: 600;
  font-size: 1rem;
  color:#272D2F;

}
.delivery_time-item p{
    color:#272D2F;
}
.footer_title{
    font-size: 1.2rem;
    font-weight: 600;
    color:#272D2F;
}

.newsletter{
    padding: 7px 15px;
    border-bottom: 1px solid #FE724C;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.newsletter span{
    background: #FE724C;
    padding: 3px 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.newsletter span i{
    color:#fff;
   
}
.newsletter input{
 background: transparent;
 border: none;
 outline: none;
}
.newsletter input:focus{
    outline: none;
}
.newsletter input::placeholder{
    color:#272D2F;
    opacity: 0.5;
}
.copyright{
    font-size: 0.8rem;
    color:#FE724C;
}
.social_links span i{
  padding: 5px;
  background:#FE724C;
  color:#fff;
  border-radius: 50%;
  cursor: pointer;
}
@media only screen and (max-width:768px) {
 .footer_title{
    font-size: 0.9rem;
 }  
 .delivery_time-list{
    font-size: .8rem;
    
 }
 .delivery_time-item span{
    font-size: 0.8rem;
   
 }
 .delivery_time-item p{
    margin-bottom: 0;
 }
    
}
@media only screen and (max-width:576px) {
    .social_links{
        justify-content: center;
       
    }
    .social_links p{
       font-size: 0.8rem; 
    }
    .social_links span{
      padding: 4px;  
    }
    .copyright{
        text-align: center;
    }
}