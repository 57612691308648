.cart_container{
    position:fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
   
    z-index: 99999;
}
.cart{
    position:absolute;
    top: 0;
    right: 0;
    width: 400px;
    height: 100%;
    background: #fff;
    z-index: 999999;
}
.cart_close{
   width: 100%; 
   height: 60px;
   padding: 10px 20px;
   
}
.cart_close span i{
    font-size: 1.3rem;
    background:#000;
    color:#fff;
    padding: 5px;
    border-radius:50%;
}
.cart_item-list{
    height:calc(100vh-140px); 
    overflow-y: scroll; 
 }
 .cart_bottom{
    position:absolute;
    bottom:0;
    left: 0;
    padding: 10px 20px;
    width: 100%;
    height: 80px;
    background: #FE724C;
 }
 .cart_bottom h6{
    font-size: 1rem;
    color:#fff;
 }
 .cart_bottom h6 span{
    font-size: 1.3rem;
    font-weight: 600;
 }
 .cart_bottom button{
    border:none;
    padding: 4px 20px;
    border-radius:5px;
    background: #42e92f;
    color: #000;
    cursor: pointer;
 }
 