@import url('https://fonts.googleapis.com/css2?family=Rubik+Microbe&display=swap');
.header{
    width: 100%;
    height: 80px;
    line-height: 80px;
}
.logo{
    text-align: center;
}
.logo_text{
    font-weight: 800;
    font-size: 30px;
    font-family: 'Rubik Microbe', cursive;
   
    
}
.logo_text span{
    font-family: 'Rubik Microbe', cursive;
    color:#FE724C;
}
.menu a{
    font-weight: 600;
    font-size: 1rem;
    padding-bottom: 10px;
    color: #272D2F !important;
    transition:.3s;
}
.menu a:hover{
    color:#FE724C !important;
}
.active_menu{
    color:#FE724C !important;
}
.cart_icon i,.user i,.mobile_menu i{
    font-size: 1.3rem;
    color:#FE724C !important;
    cursor: pointer;

}
.cart_icon{
    position: relative;
}
.cart_badge{
    position: absolute;
    top: 20px;
    right: -10px;
    background:#FE724C !important;
    color: #fff;
    width: 15px;
    height: 15px;
    border-radius:50%;
    font-size: 0.8rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mobile_menu{
    display: none;
    font-weight: 600;
}
.header_shrink{
    position: fixed;
    background: #EFBF38;
    z-index: 9999;
    top: 0;
    left: 0;
    box-shadow: 5px 5px 15px -5px #f7f7f7;

}
@media only screen and (max-width:992px) {
    /* .logo img{
        width:calc(100%-60%);
    } */
    /* .logo h5{
        font-size: .8rem;
        margin-top: -15px;
    } */
    .navigation{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background:#272D2F;
        z-index: 99;
        display: none;
    }
    .menu{
        position: absolute;
        top: 0;
        right: 0;
        width: 250px;
        height: 100%;
        background: #fff;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index:100;
        line-height: 20px;

    }
    .menu a{
        font-size: 0.9rem;
    }
    .mobile_menu{
        display: block;
    }
    .cart_icon i,.user i,.mobile_menu i{
        font-size: 1rem;
    }
    .show_menu{
        display: block;
    }
    
}
@media only screen and (max-width:768px) {
    .header{
        width: 100%;
        height: 80px;
        line-height: 80px;
    }
    /* .logo h5{
        margin-top: 0px;
    } */
    
}