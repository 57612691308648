.cart_item-info img{
   width: 40px;
   height: 40px; 
   object-fit: cover;
}
.cart_product-title{
    font-size: 0.8rem;
    font-weight: 600;
}
.cart_product-price{
    font-size: 0.8rem;
}
.cart_product-price span{
    font-size: 0.9rem;
    font-weight: 600;
    color:#FE724C;
}
.increase_decrease-btn{
    background:#FE724C ;
    padding: 2px 5px;
    border-radius: 5px;
}
.increase_btn, .decrease_btn{
  cursor: pointer;
}
.delete_btn{
    font-size: 1.1rem;
    background:#FE724C;
    font-weight: 600;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

}
.cart_item{
    padding-bottom: 10px;
    border-bottom: 1px solid #FE724C;
}
