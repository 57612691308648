.hero_content{
   padding-top: 40px; 
}
.hero_title{
    font-size: 2.5rem;
    font-family: 'Poppins', sans-serif !important;
    line-height: 55px;

}
.hero_title span{
    color: #FE724C;
}
.hero_content p{
    font-size: 1rem;
    line-height: 30px;
    color:#777;
}
.hero_btn button{
    border:none;
    outline:none;
    padding: 7px 20px;
    border-radius:5px;
    cursor:pointer;
    font-weight: 600;
}
.order_btn{
    background: #FE724C;
    color:#fff;
    transition:0.3s;
}
.order_btn:hover{
   background:transparent;
   color: #FE724C;
   border-bottom: 1px solid #FE724C;
   border-radius: 0px;
}
.all_foods-btn{
    background:transparent;
    border: 1px solid  #FE724C !important;
}
.all_foods-btn a{
    color:#FE724C !important;
}
.hero_service{
    font-weight: 600;
    font-size: 0.9rem;
}
.shipping_icon i{
 padding: 5px;
 background:#FE724C ;
 color:#fff;
 border-radius:50%;
}
.check_icon i{
    padding: 5px;
 background:#FE724C ;
 color:#fff;
 border-radius:50%;
 font-weight: 500; 
}