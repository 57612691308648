.product_item{
   border:1px solid #FE724C; 
   text-align: center;
   padding: 30px;
   cursor: pointer;
}
.product_img{
    margin-bottom: 20px;
    transition:0.4s;
}
.product_img:hover{
    transform:scale(1.2);
}
.product_content h5 a{
 color:#272D2F;
 font-size: 0.9rem;

}
.product_content h5{
    margin-bottom: 30px;
}
.product_price{
    font-weight: 600;
    font-size: 1.2rem;
    color:#FE724C;
}
.addToCart_btn{
   border:none;
   padding: 7px 18px;
   background:#FE724C; 
   color: #fff;
   border-radius:5px;
   font-size: 0.9rem;
}
@media only screen and (max-width:1024px){
    .addToCart_btn{
        padding: 5px 15px; 
        font-size: 0.8rem;
     }
     .product_content h5 a{
        font-size: 0.7rem;
    }
    .product_price{
        font-size: 1rem;
     }

}
@media only screen and (max-width:992px) {
    .addToCart_btn{
       padding: 5px 15px; 
       font-size: 0.8rem;
    }
    .product_content h5 a{
        font-size: 0.7rem;
    }
    .product_price{
        font-size: 1rem;
     }
}

@media only screen and (max-width:768px) {
    .addToCart_btn{
       padding: 5px 15px; 
       font-size: 0.8rem;
    }
    .product_content h5 a{
        font-size: 0.7rem;
    }
   
}
@media only screen and (max-width:425px){
    .product_content h5 a{
        font-size: 0.5rem;
    }
    .addToCart_btn{
        padding: 5px 15px; 
        font-size: 0.6rem;
     }
     .product_price{
        font-size: 0.9rem;
     }
}
@media only screen and (max-width:375px){
    .product_content h5 a{
        font-size: 0.4rem;
    }
    .addToCart_btn{
        padding: 5px 12px; 
        font-size: 0.4rem;
     }
     .product_price{
        font-size: 0.7rem;
     }
}