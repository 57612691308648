th{
    text-align: center;
}
.cart_image-box{
    width: 80px;
}
.cart_image-box img{
    width: 100%;
}
.cart_item-del i{
    cursor: pointer;

}
.cart_page-btn button a:hover {
    color: #fff;
  }
  
  .cart_total {
    color: #FE724C;
    font-size: 1.5rem;
  }
  
  @media only screen and (max-width: 768px) {
    th,
    td {
      font-size: 0.8rem;
    }
  }