.single_product-content{
    padding-left: 50px;
}
.product_price{
    display: flex;
    align-items: center;
    column-gap:1rem;
    margin-bottom: 0;
}
.product_price span{
    font-size: 1.3rem;

}
.category{
   font-weight: 500;
   display: flex; 
   align-items: center;
   column-gap:1rem;
   margin-top: 10px;
}
.category span{
   padding: 5px 10px; 
   background-color:#EFBF38;
   border-radius:3px;
}
.tabs{
    border-bottom:1px solid #f7f7f7;
}
.tabs h6{
    cursor: pointer;
}
.tab_content{
   padding: 30px 0px; 
}
.tab_content p{
   line-height: 30px; 
}
.tab_active{
  color:  #EFBF38;  
}
.form{
   width: 60%; 
   margin: auto;
   padding: 20px;
   background:  #EFBF38;
   border-radius:6px;
}
.form_group{
    margin-bottom: 30px;
}
.form_group input, .form_group textarea{
    width: 100%;
    background: transparent;
    padding: 7px 20px;
    border:none;
    border-bottom: 1px solid #000;
}
.form_group input:focus, .form_group textarea:focus{
    outline: none;
}
.user_name{
   font-weight: 600; 
}
.user_email{
  font-size: .8rem;  
}
.img_item{
  cursor: pointer;  
}
.related_Product-title{
   font-size: 1.4rem; 
}
@media only screen and (max-width: 768px) {
    .product_title {
      font-size: 1.2rem;
    }
    .product_price,
    .product_price span {
      font-size: 1rem;
    }
    .category,
    .category span {
      font-size: 0.8rem !important;
    }
  
    .tabs {
      margin-top: 30px;
    }
    .tabs h6 {
      font-size: 0.9rem;
    }
  
    .tab_content p,
    .user_name,
    .feedback_text {
      font-size: 0.8rem;
    }
    .user_email {
      font-size: 0.7rem;
    }
  
    .form {
      width: 100% !important;
      margin-top: 50px;
    }
  
    .form_group input::placeholder,
    .form_group textarea::placeholder {
      font-size: 0.8rem;
    }
    .related_Product-title {
      font-size: 1.2rem;
    }
  }
  
  @media only screen and (max-width: 576px) {
    .product_images {
      display: flex;
      flex-direction: row;
    }
  
    .product_mail-img {
      margin-bottom: 20px;
    }
  }